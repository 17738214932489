
import { defineComponent, reactive, toRefs } from 'vue';
import { TUIEnv } from './TUIKit/TUIPlugin';
import { api,dev } from '@/config';

export default defineComponent({
  name: 'App',
  setup() {
    let obj=reactive({
      isadmin:false,
    })
    const data = reactive({
      env: TUIEnv(),
      currentModel: 'conversation',
    });
    const handleCurrentConversation = (value: string) => {
      data.currentModel = value ? 'message' : 'conversation';
    };
    //const userID =localStorage.getItem('userID');
   // debugger;
    const userID: string | null = localStorage.getItem('userID');
    if (userID && userID.includes("pod")) {
      dev.get(`/api/im/kefuUnique?phone=${userID}`).then(res => {
        console.log('res', res);
        if (res.data.data == 0) {
          obj.isadmin = false
        } else {
          obj.isadmin = true
        }
      })
    }else {
      api.get(`/api/im/kefuUnique?phone=${userID}`).then(res => {
        console.log('res', res);
        if (res.data.data == 0) {
          obj.isadmin = false
        } else {
          obj.isadmin = true
        }
      })
    }
    return {
      obj,
      ...toRefs(data),
      handleCurrentConversation,
    };
  },
});
